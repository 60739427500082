import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack, Button, Typography, Box, styled } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import { AuthEntryContext } from "../../AuthEntryContext";
import FormProvider from "../../../../../components/hook-form";
import { StyledRoot, StyledContent } from "./styles";
import useResponsive from "../../../../../hooks/useResponsive";
import { useAuthContext } from "../../../../../auth/useAuthContext";
import { pxToRem } from "../../../../../theme/typography";
import Iconify from "../../../../../components/iconify/Iconify";
import { useStoresContext } from "../../../../../stores/useStoresContext";
import { CustomHeader } from "../../../../../components/custom-header";
import { NEW_USER, USER_UNSURE, RETURNING_USER, NEW_USER_UNCONFIRMED } from "../../../../../constants/unauthenticated-user-reasons";
import { SIGNING_IN_BY_ANSWERING, CREATING_ACCOUNT_BY_ANSWERING } from "../../../../../constants/analytics-events";

import { handleError } from "../../../../../utils/handleError";
import { handleEvent } from "../../../../../utils/handleEvent";
import SvgColor from "../../../../../components/svg-color";
import CharacterAnimation from "../../../../../animation/CharacterAnimation";
import { MobileHeader } from "../../../../../layouts/app/header";
import MobileBottomBar from "../../../../../layouts/app/bottom/MobileBottomBar";
import gumDropFireWaving from "../../../../../animation-data/gumdrop_fire_waving.json";
import pageFlip from "../../../../../animation-data/page_flip.json";
import { HEADER } from "../../../../../config-global";
import { useTheme } from '@mui/material/styles';
import WelcomeToOCDJournal from "../../../../../assets/illustrations/WelcomeToOCDJournal";

const filename = "/sections/auth/auth-entry/forms/phone-number-form/PhoneNumberForm.js";

const SPACING = 8;

function PhoneNumberForm() {
    const { state, updateFields } = useContext(AuthEntryContext);
    const { sendOTP, loginWithGoogle } = useAuthContext();
    const { layoutStore } = useStoresContext();

    const theme = useTheme();

    const isDesktop = useResponsive("up", "lg");
    const isTallScreen = useResponsive('up', '700px', undefined, 'height'); // Check if height is greater than 700px
    const [showPhoneInput, setShowPhoneInput] = useState(false);

    const PhoneSchema = Yup.object().shape({
        phone: Yup.string()
            .test("is-valid-phone", "Must be a valid phone number", value => isValidPhoneNumber(value))
            .required("Phone number is required"),
    });

    const defaultValues = {
        phone: state.phone ?? "",
    };

    const methods = useForm({
        resolver: yupResolver(PhoneSchema),
        defaultValues,
        mode: "onChange",
    });

    const {
        reset,
        handleSubmit,
        setValue,
        formState: { isValid, errors },
    } = methods;

    useEffect(() => {
        // if phone exists, then open phone with phone number editable
        if (state.phone) {
            setShowPhoneInput(true)
        }
    }, []);

    const onSubmit = async (data) => {
        try {
            const { hash } = await sendOTP(data.phone);

            if (hash) {
                updateFields({ phone: data.phone, hash });
            }
        } catch (error) {
            handleError({
                error: error,
                message: `Error submitting with phone`,
                filename,
                functionName: "onSubmit",
            });

            reset();
        }
    };

    const handlePhoneChange = (value) => {
        const phoneValue = value.startsWith("+") ? value : `+${value}`;
        setValue("phone", phoneValue, { shouldValidate: true });
    };

    const handleClose = () => {
        layoutStore.closeAuthModal();
    };

    const getCustomHeaderTitleNode = (userType) => {
        if (showPhoneInput) {
            return (
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                    <Typography variant={isDesktop ? "caption" : "body1"} sx={{
                        textAlign: "left", margin: `${pxToRem(12)} 0`, fontFamily: "VT323",
                        fontSize: pxToRem(16),
                    }}>
                        {layoutStore.isUserNew === NEW_USER ? "CREATE ACCOUNT" : "SIGN IN"}
                    </Typography>
                    <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end", fontFamily: theme.typography.fontFamilyLarge, lineHeight: 1, }}>
                        Enter your phone number
                    </Typography>
                </Box>
            )
        }

        switch (userType) {
            case NEW_USER_UNCONFIRMED:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        <Typography variant={isDesktop ? "caption" : "body1"} sx={{
                            textAlign: "left", margin: `${pxToRem(12)} 0`, fontFamily: "VT323",
                            fontSize: pxToRem(16),
                        }}>
                            {"CREATE ACCOUNT"}
                        </Typography>
                        <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end", fontFamily: theme.typography.fontFamilyLarge, lineHeight: 1, mb: isDesktop ? pxToRem(24) : pxToRem(12) }}>
                            First things first
                        </Typography>
                    </Box>
                );
            case NEW_USER:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        <Typography variant={isDesktop ? "caption" : "body1"} sx={{
                            textAlign: "left", margin: `${pxToRem(12)} 0`, fontFamily: "VT323",
                            fontSize: pxToRem(16),
                        }}>
                            {"CREATE ACCOUNT"}
                        </Typography>
                    </Box>
                );
            case RETURNING_USER:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        <Typography variant={isDesktop ? "caption" : "body1"} sx={{
                            textAlign: "left", margin: `${pxToRem(12)} 0`, fontFamily: "VT323",
                            fontSize: pxToRem(16),
                        }}>
                            {"SIGN IN"}
                        </Typography>
                        <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end", fontFamily: theme.typography.fontFamilyLarge, lineHeight: 1, mb: isDesktop ? pxToRem(12) : 0 }}>
                            Welcome back
                        </Typography>
                    </Box>
                );
            case USER_UNSURE:
                // happens when user answers question without being logged in
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end", fontFamily: theme.typography.fontFamilyLarge, lineHeight: 1, mb: isDesktop ? pxToRem(12) : 0 }}>
                            Save your answer
                        </Typography>
                    </Box>
                );
            default:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        <Typography variant={isDesktop ? "caption" : "body1"} sx={{
                            textAlign: "left", margin: `${pxToRem(12)} 0`, fontFamily: "VT323",
                            fontSize: pxToRem(16),
                        }}>
                            {"CREATE ACCOUNT"}
                        </Typography>
                        <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end" }}>
                            myocdjournal
                        </Typography>
                    </Box>
                );
        }
    };

    const getBodyContent = (userType) => {
        switch (userType) {
            case NEW_USER_UNCONFIRMED:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", marginTop: "0 !important", }} data-qa-id={"data-privacy-statement"}>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start", // Align children vertically
                                marginTop: isDesktop ? 0 : 2,
                                marginBottom: isDesktop ? 2 : 0,
                            }}
                            data-qa-id={"data-container"}
                        >
                            <Box
                                sx={{
                                    width: isDesktop ? pxToRem(40) : pxToRem(30), // Fixed width
                                    height: isDesktop ? pxToRem(40) : pxToRem(30), // Fixed height (matching width)
                                    backgroundColor: "#E5B77E", // Circular background color
                                    borderRadius: "50%", // Keeps it circular
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: pxToRem(16), // Spacing between icon and text
                                    flexShrink: 0, // Prevent circle from resizing
                                }}
                                data-qa-id={"lock-icon-circle"}
                            >
                                <Iconify
                                    icon="fa-solid:lock"
                                    sx={{
                                        width: pxToRem(14),
                                        height: pxToRem(14),
                                        color: "#6F5D45", // Lock icon color
                                    }}
                                    data-qa-id={"lock-icon"}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}
                                data-qa-id={"data-text-container"}
                            >
                                <Typography
                                    variant={isDesktop ? "h6" : "h5"}
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        marginTop: 0,
                                    }}
                                >
                                    We never share your data
                                </Typography>
                                <Typography variant="body1">
                                    Your answers are encrypted.
                                </Typography>
                            </Box>
                        </Box>


                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "flex-start", // Align children vertically
                                marginTop: isDesktop ? 0 : 2,
                                marginBottom: isDesktop ? 2 : 0,
                            }}
                            data-qa-id={"toolbox-message-container"}
                        >
                            <Box
                                sx={{
                                    width: isDesktop ? pxToRem(40) : pxToRem(30), // Fixed width
                                    height: isDesktop ? pxToRem(40) : pxToRem(30), // Fixed height (matching width)
                                    backgroundColor: "#E5B77E", // Circular background color
                                    borderRadius: "50%", // Keeps it circular
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: pxToRem(16), // Spacing between icon and text
                                    flexShrink: 0, // Prevent circle from resizing
                                }}
                                data-qa-id={"heart-icon-circle"}
                            >
                                <Iconify
                                    icon="fa-solid:heart"
                                    sx={{
                                        width: pxToRem(14),
                                        height: pxToRem(14),
                                        color: "#6F5D45", // Lock icon color
                                    }}
                                    data-qa-id={"heart-icon"}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}
                                data-qa-id={"toolbox-text-container"}
                            >
                                <Typography
                                    variant={isDesktop ? "h6" : "h5"}
                                    sx={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        marginTop: 0,
                                    }}
                                >
                                    We understand OCD isn’t easy
                                </Typography>
                                <Typography variant="body1">
                                    Journaling about it can be tough, which is why we’ve created a{' '}
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            whiteSpace: 'nowrap', // Prevents line breaks within this span
                                        }}
                                    >
                                        toolbox (
                                        <Iconify
                                            icon="fa6-solid:toolbox"
                                            sx={{
                                                width: pxToRem(20),
                                                height: pxToRem(14),
                                                color: theme.palette.secondary.contrastText,
                                            }}
                                        />
                                        )
                                    </Box>{' '}
                                    of resources to help. And remember, it’s always okay to skip a prompt.
                                </Typography>
                            </Box>
                        </Box>

                        {
                            isDesktop && (

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start", // Align children vertically
                                        marginTop: isDesktop ? 0 : 2,
                                        marginBottom: isDesktop ? 2 : 0,
                                    }}
                                    data-qa-id={"age-message-container"}
                                >
                                    <Box
                                        sx={{
                                            width: isDesktop ? pxToRem(40) : pxToRem(30), // Fixed width
                                            height: isDesktop ? pxToRem(40) : pxToRem(30), // Fixed height (matching width)
                                            backgroundColor: "#E5B77E", // Circular background color
                                            borderRadius: "50%", // Keeps it circular
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginRight: pxToRem(16), // Spacing between icon and text
                                            flexShrink: 0, // Prevent circle from resizing
                                        }}
                                        data-qa-id={"gate-icon-circle"}
                                    >
                                        <Iconify
                                            icon="fa6-solid:torii-gate"
                                            sx={{
                                                width: pxToRem(14),
                                                height: pxToRem(14),
                                                color: "#6F5D45", // Lock icon color
                                            }}
                                            data-qa-id={"gate-icon"}
                                        />
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                        }}
                                        data-qa-id={"age-message-container"}
                                    >
                                        <Typography
                                            variant={isDesktop ? "h6" : "h5"}
                                            sx={{
                                                display: "flex",
                                                alignItems: "flex-end",
                                                marginTop: 0,
                                            }}
                                        >
                                            You must be old enough
                                        </Typography>
                                        <Typography variant="body1">
                                            You must be at least 13 years old and meet your country's age requirement to use these services.
                                        </Typography>
                                    </Box>
                                </Box>
                            )
                        }

                    </Box >
                );
            case NEW_USER:
                if (isDesktop) {
                    return (
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", width: "100%", }}>
                                <Typography variant={isDesktop ? "h4" : "h3"} sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    mb: pxToRem(12),
                                    fontFamily: theme.typography.fontFamilyLarge,
                                    lineHeight: 1
                                }}
                                >
                                    Your OCD journal awaits
                                </Typography>
                                <Typography variant="body1">
                                    A daily journal designed for self-reflection. Create an account to keep the page turning.
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mt: 2, width: "100%", background: "#0AACAC", borderRadius: 2 }} data-qa-id={"ocd-journal-image-container"}>
                                <WelcomeToOCDJournal />
                            </Box>
                        </Box>
                    )
                }

                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "0 !important" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", mb: 2, }}>
                            <Typography variant={isDesktop ? "h4" : "h3"} sx={{ display: "flex", alignItems: "flex-end", mb: pxToRem(12), fontFamily: theme.typography.fontFamilyLarge, lineHeight: 1, }}>
                                Your OCD journal awaits
                            </Typography>
                            <Typography variant="body1">
                                A daily journal designed for self-reflection. Create an account to keep the page turning.
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", background: "#0AACAC", borderRadius: 2 }} data-qa-id={"ocd-journal-image-container"}>
                            <WelcomeToOCDJournal />
                        </Box>
                    </Box>
                );
            case RETURNING_USER:
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: isDesktop ? "0 !important" : `${pxToRem(12)} !important`, }}>
                        <Typography variant="body1" sx={{ width: "100%" }}>
                            We're excited to see you again. Sign in to get back to journaling.
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center", mt: 2, width: "100%", background: "#0AACAC", borderRadius: 2 }} data-qa-id={"ocd-journal-image-container"}>
                            <WelcomeToOCDJournal />
                        </Box>
                    </Box>
                );
            case USER_UNSURE:
                // happens when user answers question without being logged in
                return (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="body1" sx={{ width: "100%" }}>
                            Our daily journal helps those with OCD build stronger relationships with themselves.
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: pxToRem(32) }}>
                            <CharacterAnimation
                                animationData={gumDropFireWaving}
                                style={{
                                    height: isDesktop ? pxToRem(180) : (isTallScreen ? pxToRem(180) : pxToRem(140)),
                                    width: isDesktop ? pxToRem(180) : (isTallScreen ? pxToRem(180) : pxToRem(160)),
                                }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: pxToRem(16) }} data-qa-id={"streaks-container"}>
                            <StreakButton sx={{ marginRight: pxToRem(8) }} isDesktop={isDesktop} showAsActive={true} disabled={true}>
                                <IconWrapper isDesktop={isDesktop}>
                                    <SvgColor src={`/assets/icons/streak/fire.svg`} sx={{ width: pxToRem(16), height: pxToRem(16), color: "#FFFFFF" }} data-qa-id={"streak-icon"} />
                                </IconWrapper>
                                <AnimatedTypography>
                                    Day 1
                                </AnimatedTypography>
                            </StreakButton>

                            <StreakButton sx={{ marginRight: pxToRem(8) }} isDesktop={isDesktop} disabled={true}>
                                <IconWrapper isDesktop={isDesktop} disabled={true}>
                                    <SvgColor src={`/assets/icons/streak/fire.svg`} sx={{ width: pxToRem(16), height: pxToRem(16), color: "#FFFFFF" }} data-qa-id={"streak-icon"} />
                                </IconWrapper>
                                <AnimatedTypography>
                                    Day 2
                                </AnimatedTypography>
                            </StreakButton>

                            <StreakButton isDesktop={isDesktop} disabled={true}>
                                <IconWrapper isDesktop={isDesktop} disabled={true}>
                                    <SvgColor src={`/assets/icons/streak/fire.svg`} sx={{ width: pxToRem(16), height: pxToRem(16), color: "#FFFFFF" }} data-qa-id={"streak-icon"} />
                                </IconWrapper>
                                <AnimatedTypography>
                                    Day 3
                                </AnimatedTypography>
                            </StreakButton>
                        </Box>
                    </Box>
                );
            default:
                return (
                    <Typography variant="body1">
                        A daily journal designed for self-reflection. Create an account to keep the page turning.
                    </Typography>
                );
        }
    };

    const customHeaderTitleNode = getCustomHeaderTitleNode(layoutStore.isUserNew);
    const bodyContent = getBodyContent(layoutStore.isUserNew);

    const renderButtons = () => {
        if (layoutStore.isUserNew === NEW_USER_UNCONFIRMED) {
            if (isDesktop) {
                return (
                    <Stack direction="row" justifyContent="center" spacing={2} sx={{ width: 1, marginTop: `${pxToRem(40)} !important` }}>
                        <Button
                            fullWidth
                            size="medium"
                            variant="contained"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textTransform: "none",
                            }}
                            onClick={() => {
                                layoutStore.setIsUserNew(NEW_USER);
                            }}
                        >
                            I understand
                        </Button>
                    </Stack>
                );
            }

            return (
                <Stack direction="column" justifyContent="center" spacing={2} sx={{ width: 1, margin: "auto" }}>
                    <Typography
                        component="div"
                        sx={{ color: "text.primary", mb: 2, typography: "caption", textAlign: "left" }}
                        data-qa-id="phone-number-terms-privacy-container"
                    >
                        {"I confirm I am at least 13 years old and meet my country's age requirement to use these services."}
                    </Typography>
                    <Button
                        fullWidth
                        size="medium"
                        variant="contained"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textTransform: "none",
                        }}
                        onClick={() => {
                            layoutStore.setIsUserNew(NEW_USER);
                        }}
                    >
                        I understand
                    </Button>
                </Stack>
            );
        }

        if (layoutStore.isUserNew === USER_UNSURE) {
            // case of user answering a question and onboarding/signing in from there
            if (isDesktop) {
                return (
                    <Stack direction="row" justifyContent="center" spacing={2} sx={{ width: 1, marginTop: `${pxToRem(40)} !important` }}>
                        <Button
                            fullWidth
                            size="medium"
                            variant="outlined"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                layoutStore.setIsUserNew(RETURNING_USER);

                                handleEvent({
                                    eventName: SIGNING_IN_BY_ANSWERING,
                                    isDesktop: isDesktop,
                                });
                            }}
                        >
                            I already have an account
                        </Button>
                        <Button
                            fullWidth
                            size="medium"
                            variant="contained"
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                layoutStore.setIsUserNew(NEW_USER_UNCONFIRMED);

                                handleEvent({
                                    eventName: CREATING_ACCOUNT_BY_ANSWERING,
                                    isDesktop: isDesktop,
                                });
                            }}
                        >
                            Get started
                        </Button>
                    </Stack>
                );
            }

            return (
                <Stack direction="column" justifyContent="center" spacing={2} sx={{ width: 1, margin: "auto" }}>
                    <Button
                        fullWidth
                        size="medium"
                        variant="contained"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textTransform: "none",
                        }}
                        onClick={() => {
                            layoutStore.setIsUserNew(NEW_USER_UNCONFIRMED);

                            handleEvent({
                                eventName: CREATING_ACCOUNT_BY_ANSWERING,
                                isDesktop: isDesktop,
                            });
                        }}
                    >
                        Get started
                    </Button>
                    <Button
                        fullWidth
                        size="medium"
                        variant="outlined"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textTransform: "none",
                        }}
                        onClick={() => {
                            layoutStore.setIsUserNew(RETURNING_USER);

                            handleEvent({
                                eventName: SIGNING_IN_BY_ANSWERING,
                                isDesktop: isDesktop,
                            });
                        }}
                    >
                        I already have an account
                    </Button>
                </Stack>
            );
        }

        if (isDesktop) {
            return (
                <Stack direction="row" justifyContent="center" spacing={2} sx={{ width: 1, marginTop: `${pxToRem(40)} !important` }}>
                    <Button
                        fullWidth
                        size="medium"
                        variant="contained"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={() => setShowPhoneInput(true)}
                    >
                        <Iconify icon="mdi:phone" sx={{ mr: 1 }} />
                        Continue with Phone
                    </Button>

                    <Button
                        fullWidth
                        size="medium"
                        variant="contained"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={loginWithGoogle}
                    >
                        <Iconify icon="mdi:gmail" sx={{ mr: 1 }} />
                        Continue with Google
                    </Button>
                </Stack>
            );
        }

        return (
            <Stack direction="column" justifyContent="center" spacing={2} sx={{ width: 1, margin: "auto" }} data-qa-id={"continue-icons-row"}>
                <Button
                    fullWidth
                    size="medium"
                    variant="outlined"
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onClick={() => setShowPhoneInput(true)}
                >
                    <Iconify icon="mdi:phone" sx={{ mr: 1 }} />
                    Continue with Phone
                </Button>

                <Button
                    fullWidth
                    size="medium"
                    variant="outlined"
                    sx={{
                        display: "flex !important",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onClick={loginWithGoogle}
                >
                    <Iconify icon="mdi:gmail" sx={{ mr: 1 }} />
                    Continue with Google
                </Button>
            </Stack>
        );
    };

    return (
        <StyledRoot isDesktop={isDesktop} data-qa-id="phone-number-root-container">
            {isDesktop ? (
                <CustomHeader
                    onClose={handleClose}
                    closeIconKey="mingcute:close-fill"
                    titleNode={customHeaderTitleNode}
                    customStyles={{ height: "fit-content !important", minHeight: "fit-content !important" }}
                />

            ) : (
                <MobileHeader
                    onClose={handleClose}
                    closeIconKey="mingcute:close-fill"
                />
            )}
            <StyledContent isDesktop={isDesktop} data-qa-id="phone-number-content-container">
                <Stack spacing={isDesktop ? 4 : 2} sx={{ mt: 0, mb: 4, width: "100%", paddingTop: isDesktop ? "0" : pxToRem(HEADER.H_MOBILE + SPACING), }} data-qa-id="phone-number-stack-container">
                    {!isDesktop && customHeaderTitleNode}

                    {!showPhoneInput && bodyContent}

                    {showPhoneInput ? (
                        <FormProvider methods={methods} customStyle={{ marginTop: pxToRem(12), }} onSubmit={handleSubmit(onSubmit)}>
                            <Stack
                                direction={"column"}
                                justifyContent={"center"}
                                spacing={2}
                                sx={{ width: 1, margin: `0 !important auto auto auto` }}
                                data-qa-id="phone-number-form-container"
                            >
                                <Controller
                                    name="phone"
                                    control={methods.control}
                                    render={({ field }) => (
                                        <>
                                            <PhoneInput
                                                {...field}
                                                country={"us"}
                                                onChange={(value, country) => handlePhoneChange(value, country)}
                                                inputStyle={{
                                                    width: "100%",
                                                    height: "56px",
                                                    fontSize: "16px",
                                                    paddingLeft: "48px",
                                                    borderRadius: "4px",
                                                    border: "1px solid #ccc",
                                                }}
                                                inputProps={{
                                                    name: "phone",
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                            />
                                            {errors.phone && (
                                                <Typography variant="body2" color="error">
                                                    {errors.phone.message}
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                />
                                <Typography variant="body2" color="textSecondary" sx={{ mt: `${pxToRem(12)} !important` }}>
                                    We'll send you a text with a verification code. Message and data rates may apply.
                                </Typography>

                                {isDesktop ? (
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        disabled={!isValid}
                                        sx={{ marginTop: `${pxToRem(40)} !important` }}
                                        data-qa-id="phone-number-next-button"
                                    >
                                        Next
                                    </Button>
                                ) : (
                                    <MobileBottomBar>
                                        <Button
                                            fullWidth
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            disabled={!isValid}
                                            sx={{
                                                textTransform: "none",
                                            }}
                                            data-qa-id="phone-number-next-button"
                                        >
                                            Send code
                                        </Button>
                                    </MobileBottomBar>
                                )}
                            </Stack>
                        </FormProvider>
                    ) : (
                        <>
                            {isDesktop ? (
                                renderButtons()
                            ) : (
                                <MobileBottomBar>
                                    {renderButtons()}
                                </MobileBottomBar>
                            )}
                        </>
                    )}
                </Stack>
            </StyledContent>
        </StyledRoot>
    );
}

const StreakButton = styled(Button)(({ theme, disabled = false, showAsActive = false }) => ({
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    minWidth: "fit-content",
    backgroundColor: theme.palette.background.lightNeutral,
    color: "#069383",
    border: `${pxToRem(2)} solid ${disabled && !showAsActive ? "#AEA7A7" : theme.palette.card.borderStroke}`,
    padding: pxToRem(6),
    "&:hover": {
        backgroundColor: theme.palette.button.hover.outlinedBackground,

    },
}));

const IconWrapper = styled(Box)(({ theme, isDesktop, disabled = false }) => ({
    backgroundColor: disabled ? "#AEA7A7" : "#FC8800",
    borderRadius: isDesktop ? theme.shape.borderRadius : theme.shape.borderRadius / 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: pxToRem(10),
    padding: theme.spacing(isDesktop ? 1 : 0.5),
}));

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: pxToRem(16),
    color: theme.palette.secondary.contrastText,
}));

export default observer(PhoneNumberForm);