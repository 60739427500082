import PropTypes from "prop-types";
import { observer } from 'mobx-react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useResponsive from "../../../hooks/useResponsive";
import { Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import BaseModal from "../../../components/base-modal/BaseModal";
import { RHFTextField } from "../../../components/hook-form";
import FormProvider from "../../../components/hook-form/FormProvider";
import { pxToRem } from "../../../theme/typography";

// ----------------------------------------------------------------------

const JournalUpdateModal = observer(({ open, journalName, onSave, onClose, isSubmitting }) => {
    const isDesktop = useResponsive("up", "lg");

    const JournalNameSchema = Yup.object().shape({
        journal_name: Yup.string().required('Journal name required').max(150, 'Maximum 150 characters'),
    });

    const defaultValues = {
        journal_name: journalName ?? '',
    };

    const methods = useForm({
        resolver: yupResolver(JournalNameSchema),
        defaultValues,
        mode: "onChange",
    });

    const {
        handleSubmit,
        formState: { isValid },
    } = methods;

    const onSubmit = async (data) => {
        await onSave({ journalName: data.journal_name });

        onClose();
    };

    return (
        <BaseModal
            open={open}
            onClose={onClose}
            title="New journal name"
            customTitleStyles={{ marginBottom: "0 !important" }}
            customChildrenStyles={{ marginTop: pxToRem(12) }}
            subheaderText="JOURNAL"
        >
            <Stack spacing={isDesktop ? 4 : 2} sx={{ marginTop: isDesktop ? "inherit" : `${pxToRem(8)} !important` }} data-qa-id="journal-name-content-container">
                <FormProvider
                    methods={methods}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Stack direction={"column"} justifyContent={"center"} spacing={2} sx={{ width: 1, margin: isDesktop ? "auto" : "inherit" }} data-qa-id="journal-name-form-container">
                        <RHFTextField
                            name="journal_name"
                            label="Journal Name"
                            autoFocus={true}
                        />

                        <LoadingButton
                            fullWidth
                            size="medium"
                            type="submit"
                            variant="contained"
                            disabled={!isValid}
                            loading={isSubmitting}
                            sx={{ marginTop: `${pxToRem(40)} !important` }}
                            data-qa-id="journal-name-next-button"
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
                </FormProvider>
            </Stack>
        </BaseModal>
    );
});
// tester
JournalUpdateModal.propTypes = {
    open: PropTypes.bool,
    journalName: PropTypes.string,
    onClose: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
};

export default JournalUpdateModal;
