import React, { useState, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Button, AppBar } from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import useResponsive from '../../../hooks/useResponsive';
import { useSpring, animated, useTransition } from '@react-spring/web';
import { handleEvent } from '../../../utils/handleEvent';
import { COMPLETED_COPING_STRATEGY_PREMATURELY, FINISHED_DEEP_BREATH_STEP } from '../../../constants/analytics-events';
import { pxToRem } from '../../../theme/typography';
import { styled, useTheme } from '@mui/material/styles';
import { HEADER } from '../../../config-global';

const phases = [
    { label: 'Inhale', duration: 4000 },
    { label: 'Hold', duration: 4000 },
    { label: 'Exhale', duration: 6000 },
];

const DeepBreathStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();
    const isDesktop = useResponsive('up', 'lg');

    const theme = useTheme();

    // State variables
    const [phase, setPhase] = useState('');
    const [isBreathing, setIsBreathing] = useState(false);
    const [showInitialText, setShowInitialText] = useState(true);
    const [showContinueButton, setShowContinueButton] = useState(false);
    const [breathCount, setBreathCount] = useState(0);

    // Circle size range, memoized to prevent unnecessary re-renders
    const minSize = useMemo(() => (isDesktop ? 150 : 120), [isDesktop]);
    const maxSize = useMemo(() => (isDesktop ? 300 : 250), [isDesktop]);

    const startTimeRef = useRef(null);
    const eventSentRef = useRef(false); // Flag to prevent multiple event sends

    // Set the start time when the component mounts
    useEffect(() => {
        startTimeRef.current = Date.now();
        console.log('Component mounted, start time set:', startTimeRef.current);

        return () => {
            console.log('Component unmounted, sending analytics event...');
            // Send the event if the component unmounts unexpectedly
            sendAnalyticsEvent();
        };
    }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

    // Function to send the analytics event
    const sendAnalyticsEvent = () => {
        if (eventSentRef.current) {
            return; // Event has already been sent, do nothing
        }
        eventSentRef.current = true; // Mark the event as sent

        const endTimestamp = Date.now();
        const durationMs = endTimestamp - startTimeRef.current;
        const durationSeconds = durationMs / 1000;

        console.log('Sending analytics event with data:', {
            duration_seconds: durationSeconds,
            breath_count: breathCount,
            isDesktop,
        });

        handleEvent({
            eventName: FINISHED_DEEP_BREATH_STEP,
            isDesktop,
            properties: {
                duration_seconds: durationSeconds,
                breath_count: breathCount,
            },
        });
    };

    // Adjust handleContinue to send the event before proceeding
    const handleContinue = () => {
        console.log('Continue button clicked');
        sendAnalyticsEvent(); // Send the event
        copingStatementStore.goToNextStep();
    };

    // Adjust onEnd to send the event before ending
    const handleEnd = () => {
        console.log('End button clicked');
        sendAnalyticsEvent(); // Send the event

        handleEvent({
            eventName: COMPLETED_COPING_STRATEGY_PREMATURELY,
            isDesktop,
            properties: {
                ending_screen: "deep_breath_step"
            }
        });
        onEnd();
    };

    // Animation spring for circle size
    const [styles, api] = useSpring(() => ({
        size: minSize,
        config: { duration: phases[0].duration },
    }));

    // Transition for phase text
    const transitions = useTransition(phase, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 500 },
    });

    // Breathing sequence
    useEffect(() => {
        if (!isBreathing) return;

        let isMounted = true;

        const breathingSequence = async () => {
            // Delay before starting the breathing animation
            await new Promise((resolve) => setTimeout(resolve, 500));

            while (isMounted) {
                // Start of one full breath cycle
                for (let i = 0; i < phases.length; i++) {
                    const currentPhase = phases[i];
                    setPhase(currentPhase.label);

                    // Update circle size for Inhale and Exhale
                    if (currentPhase.label === 'Inhale') {
                        api.start({
                            size: maxSize,
                            config: {
                                duration: currentPhase.duration,
                                easing: (t) => t,
                            },
                        });
                    } else if (currentPhase.label === 'Exhale') {
                        api.start({
                            size: minSize,
                            config: {
                                duration: currentPhase.duration,
                                easing: (t) => t,
                            },
                        });
                    } else if (currentPhase.label === 'Hold') {
                        // Keep the circle size constant during Hold
                        api.start({
                            size: styles.size.get(),
                            config: { duration: currentPhase.duration },
                        });
                    }

                    // Wait for the duration of the current phase
                    await new Promise((resolve) =>
                        setTimeout(resolve, currentPhase.duration)
                    );
                }

                // End of one full breath cycle
                // Increment breath count
                setBreathCount((prevCount) => {
                    const newCount = prevCount + 1;
                    console.log('Breath cycle completed, new breath count:', newCount);
                    return newCount;
                });

                // After first loop, show continue button
                if (!showContinueButton) {
                    setShowContinueButton(true);
                }
            }
        };

        breathingSequence();

        return () => {
            isMounted = false;
        };
    }, [isBreathing, api, minSize, maxSize, styles.size, showContinueButton]);

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
            }}
            data-qa-id="deep-breath-step"
        >

            {/* Top bar with End button */}
            <StyledAppBar data-qa-id="header">
                <Button
                    onClick={handleEnd}
                    sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                        color: "inherit"
                    }}
                    data-qa-id="end-button"
                >
                    Back to my journal
                </Button>
            </StyledAppBar>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    marginTop: pxToRem(24 + HEADER.H_MOBILE),
                }}
                data-qa-id="content-container"
            >
                {/* Initial Text */}
                {showInitialText && (
                    <Typography
                        variant={isDesktop ? 'h3' : 'h4'}
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginBottom: 4,
                            position: 'absolute',
                            top: '16%',
                            color: "inherit",
                            fontFamily: theme.typography.fontFamilyLarge,
                        }}
                        data-qa-id="initial-text"
                    >
                        Let's start by taking a deep breath
                    </Typography>
                )}

                {/* Animated Circle */}
                <animated.div
                    style={{
                        width: styles.size.to((s) => `${s}px`),
                        height: styles.size.to((s) => `${s}px`),
                        borderRadius: '50%',
                        backgroundColor: 'rgba(255, 255, 255, 0.6)', // Semi-transparent white
                        border: `black ${pxToRem(2)} solid`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 0 10px rgba(0,0,0,0.2)',
                        cursor: !isBreathing ? 'pointer' : 'default',
                    }}
                    data-qa-id="breathing-circle"
                    onClick={() => {
                        if (!isBreathing) {
                            console.log('Breathing exercise started');
                            setIsBreathing(true);
                            setShowInitialText(false); // Hide initial text when breathing starts
                        }
                    }}
                >
                    {!isBreathing ? (
                        <Button
                            variant="text"
                            data-qa-id="start-button"
                        >
                            <Typography
                                variant={isDesktop ? 'h4' : 'h5'}
                                sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    color: "#2B3A6C"
                                }}
                                data-qa-id="start-button-text"
                            >
                                Start
                            </Typography>
                        </Button>
                    ) : (
                        transitions((style, item) => (
                            <animated.div style={{ ...style, position: 'absolute' }}>
                                <Typography
                                    variant={isDesktop ? 'h4' : 'h5'}
                                    sx={{
                                        fontWeight: 'bold',
                                        textShadow: '1px 1px 2px rgba(255,255,255,0.7)',
                                        textAlign: 'center',
                                        color: "#2B3A6C"
                                    }}
                                    data-qa-id="breathing-phase-text"
                                >
                                    {item}
                                </Typography>
                            </animated.div>
                        ))
                    )}
                </animated.div>
            </Box>

            {/* Continue Button */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 2,
                    width: isDesktop ? '50%' : '100%',
                    margin: 'auto',
                }}
                data-qa-id="continue-button-container"
            >
                <Button
                    variant="contained"
                    fullWidth={true}
                    disabled={!showContinueButton}
                    onClick={handleContinue}
                    sx={{
                        backgroundColor: '#2B3A6C !important',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        opacity: showContinueButton ? 1 : 0,
                        '&:hover': {
                            backgroundColor: '#2B3A6C !important',
                            '&::before': {
                                backgroundColor: "#170161 !important",

                            },
                        },
                        '&::before': {
                            backgroundColor: "#170161 !important",

                        },
                        '&.Mui-disabled': {
                            backgroundColor: '#2B3A6C !important',
                            '&::before': {
                                backgroundColor: "#170161 !important",

                            },
                        },
                    }}
                    data-qa-id="continue-button"
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    height: HEADER.H_MOBILE,
    zIndex: theme.zIndex.appBar + 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    backgroundColor: "#77ADFF",
    backgroundSize: "20px 20px",
    position: 'fixed',
    paddingRight: pxToRem(16),
    color: "inherit",
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: pxToRem(2),
        backgroundColor: "#5C96EC",
    },
}));

DeepBreathStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default DeepBreathStep;
