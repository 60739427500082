// components/BaseModal.js

import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Card, Box, Typography, Stack, styled } from '@mui/material';
import { pxToRem } from '../../theme/typography';
import useResponsive from '../../hooks/useResponsive';
import CustomHeader from '../custom-header/CustomHeader';
import { MobileHeader } from '../../layouts/app/header';
import { HEADER } from '../../config-global';
import { useTheme } from '@mui/material/styles';

const BaseModal = ({
    open,
    onClose,
    title,
    subheaderText,
    description,
    getImageComponent,
    children,
    customStyles,
    closeIconKey,
    fullScreen = false,
    noMarginHeader = false,
    customTitleStyles,
    customChildrenStyles,
    customDescriptionStyles
}) => {
    const isDesktop = useResponsive('up', 'lg');

    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            fullScreen={fullScreen}
            PaperProps={{
                sx: {
                    overflow: 'hidden', // Ensure no horizontal overflow
                    ...customStyles,
                },
            }}
            data-qa-id={"base-modal"}
        >
            <StyledCard isDesktop={isDesktop} isFullScreen={fullScreen} data-qa-id={"base-modal-card"}>
                <StyledRoot isDesktop={isDesktop} data-qa-id="modal-root-container">
                    {/* If fullScreen, we show a top mobile header */}
                    {fullScreen && (
                        <MobileHeader
                            onClose={onClose}
                            simpleMode={true}
                            closeIconKey="mingcute:close-fill"
                        />
                    )}

                    {getImageComponent && (
                        <Stack
                            spacing={isDesktop ? 4 : 2}
                            sx={{ mt: isDesktop ? 2 : 0, mb: 4, width: "100%" }}
                            data-qa-id="modal-content-stack"
                        >
                            {getImageComponent()}
                        </Stack>
                    )}

                    {/* The top area: a custom header that can hold subheader + title */}
                    <CustomHeader
                        titleNode={
                            <Stack
                                // spacing={subheaderText ? 1 : 0}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                            >

                                {/* Only render subheaderText if present */}
                                {subheaderText && (
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            textAlign: 'left',
                                            marginBottom: pxToRem(12),
                                            lineHeight: 1,
                                            fontFamily: "VT323",
                                            fontSize: pxToRem(16),
                                        }}
                                    >
                                        {subheaderText}
                                    </Typography>
                                )}

                                <Typography
                                    variant="h4"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        marginBottom: pxToRem(12),
                                        fontFamily: theme.typography.fontFamilyLarge, lineHeight: 1,
                                        ...customTitleStyles,
                                    }}
                                >
                                    {title}
                                </Typography>

                                {!subheaderText && description && (
                                    <Typography variant="body1" sx={{ textAlign: 'left', width: "100%", mt: 1, ...customDescriptionStyles, }}>
                                        {description}
                                    </Typography>
                                )}
                            </Stack>
                        }
                        customStyles={{
                            marginTop: (fullScreen && !noMarginHeader) ? pxToRem(HEADER.H_MOBILE) : 'inherit',
                        }}
                        onClose={!fullScreen ? onClose : undefined}
                        closeIconKey={closeIconKey}
                    />

                    {/* The main content below */}
                    <StyledContent sx={{ marginTop: 0 }} data-qa-id="modal-content-container">
                        {subheaderText && description && (
                            <Typography variant="body1" sx={{ textAlign: 'left', width: "100%" }}>
                                {description}
                            </Typography>
                        )}

                        {/* Container that wraps the caller's children */}
                        <Stack
                            spacing={isDesktop ? 4 : 2}
                            sx={{ mt: isDesktop ? 2 : 0, mb: 4, width: "100%", ...customChildrenStyles }}
                            data-qa-id="modal-content-stack"
                        >
                            {children}
                        </Stack>
                    </StyledContent>
                </StyledRoot>
            </StyledCard>
        </Dialog>
    );
};

BaseModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    subheaderText: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.node,
    getImageComponent: PropTypes.func,
    customStyles: PropTypes.object,
    closeIconKey: PropTypes.string,
    fullScreen: PropTypes.bool,
    noMarginHeader: PropTypes.bool,
    customTitleStyles: PropTypes.object,
    customChildrenStyles: PropTypes.object,
    customDescriptionStyles: PropTypes.object,
};

BaseModal.defaultProps = {
    closeIconKey: "mingcute:close-fill",
    fullScreen: false,
};

const StyledCard = styled(Card)(({ theme, isDesktop, isFullScreen }) => ({
    maxWidth: pxToRem(800),
    margin: 'auto',
    borderRadius: isDesktop
        ? theme.shape.borderRadius * 2
        : isFullScreen
            ? 0
            : theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[0],
    height: isDesktop ? "inherit" : "100%",
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    border: isDesktop
        ? `${pxToRem(2)} solid ${theme.palette.card.borderStroke}`
        : isFullScreen
            ? "inherit"
            : `${pxToRem(2)} solid ${theme.palette.card.borderStroke}`,
}));

const StyledRoot = styled('main')(({ theme, isDesktop }) => ({
    padding: isDesktop ? theme.spacing(4, 6, 0, 6) : theme.spacing(4, 2, 0, 2),
}));

const StyledContent = styled('div')(() => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

export default BaseModal;
