import React from 'react';
import { Typography, Box, IconButton, Stack } from '@mui/material';
import { pxToRem } from '../../../theme/typography';

const totalSteps = 6;

const getOnboardingTourSteps = ({ layoutStore, selectedQuestionId = null, isDesktop }) => {
  return [
    // 1) first step
    {
      target: selectedQuestionId ? `.front-question-card-${selectedQuestionId}` : ".front-question-card",
      content: (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            backgroundColor: '#FFEFB7',
            borderRadius: 2,
            color: 'inherit',
            zIndex: 0,
            padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
            gap: pxToRem(8),
            position: 'relative',
          }}
          data-qa-id="first-tour-step"
        >
          {/* Left Column: main text + "Continue" */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="body1" sx={{ color: 'inherit', textAlign: "left" }}>
              Let’s start with the main course! This is where you’ll see today’s prompt.
            </Typography>

            <Stack direction="row" alignItems="end" justifyContent="space-between" sx={{ mt: 2 }}>
              <IconButton
                size="medium"
                variant="outlined"
                sx={{
                  borderRadius: pxToRem(8),
                  border: `${pxToRem(1)} solid`,
                  padding: `${pxToRem(4)} ${pxToRem(8)}`,
                  width: "max-content",
                  height: pxToRem(28),
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => layoutStore.goToNextStep()}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  Continue
                </Typography>
              </IconButton>
            </Stack>
          </Box>

          {/* Right Column: X + step counter */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <IconButton
              onClick={() => layoutStore.closeTour()}
              sx={{
                backgroundColor: 'transparent',
                fontSize: '1.2rem',
                alignSelf: 'flex-end',
                p: 0,
                mb: 2,
              }}
            >
              ✕
            </IconButton>

            <Typography
              variant="caption"
              sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
            >
              {layoutStore.tourStepIndex + 1}/{totalSteps}
            </Typography>
          </Box>
        </Box>
      ),
      placement: 'bottom',
      disableBeacon: true,
    },

    // 2) second step
    {
      ...(isDesktop
        ? {
          target: ".previous-date-button",
          content: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                backgroundColor: '#FFEFB7',
                borderRadius: 2,
                color: 'inherit',
                zIndex: 0,
                padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
                gap: pxToRem(8),
                position: 'relative',
              }}
              data-qa-id="second-tour-step-desktop"
            >
              {/* Left column: text only in this one */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: 'inherit', textAlign: 'left' }}>
                  Now try clicking the left arrow to see yesterday’s prompt
                </Typography>
              </Box>

              {/* Right column: X + step */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <IconButton
                  onClick={() => layoutStore.closeTour()}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '1.2rem',
                    alignSelf: 'flex-end',
                    p: 0,
                    mb: 2,
                  }}
                >
                  ✕
                </IconButton>

                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
                >
                  {layoutStore.tourStepIndex + 1}/{totalSteps}
                </Typography>
              </Box>
            </Box>
          ),
          placement: 'top',
          disableBeacon: true,
          styles: {
            overlay: {
              pointerEvents: 'none',
            },
          },
        }
        : {
          target: selectedQuestionId ? `.front-question-card-${selectedQuestionId}` : ".front-question-card",
          content: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                backgroundColor: '#FFEFB7',
                borderRadius: 2,
                color: 'inherit',
                zIndex: 0,
                padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
                gap: pxToRem(8),
                position: 'relative',
              }}
              data-qa-id="second-tour-step-mobile"
            >
              {/* Left column: text only */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: 'inherit', textAlign: 'left' }}>
                  Now try swiping right to see yesterday’s prompt
                </Typography>
              </Box>

              {/* Right column: X + step */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <IconButton
                  onClick={() => layoutStore.closeTour()}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '1.2rem',
                    alignSelf: 'flex-end',
                    p: 0,
                    mb: 2,
                  }}
                >
                  ✕
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
                >
                  {layoutStore.tourStepIndex + 1}/{totalSteps}
                </Typography>
              </Box>
            </Box>
          ),
          placement: 'top',
          disableBeacon: true,
          styles: {
            overlay: {
              pointerEvents: 'none',
            },
          },
        }),
    },

    // 3) third step
    {
      ...(isDesktop
        ? {
          target: ".jump-to-today-button-desktop",
          content: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                backgroundColor: '#FFEFB7',
                borderRadius: 2,
                color: 'inherit',
                zIndex: 0,
                padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
                gap: pxToRem(8),
                position: 'relative',
              }}
              data-qa-id="third-tour-step-desktop"
            >
              {/* Left column: text */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: 'inherit', textAlign: 'left' }}>
                  Good work! And you can use the 'Skip' button to skip to today.
                </Typography>
              </Box>

              {/* Right column: X + step */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <IconButton
                  onClick={() => layoutStore.closeTour()}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '1.2rem',
                    alignSelf: 'flex-end',
                    p: 0,
                    mb: 2,
                  }}
                >
                  ✕
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
                >
                  {layoutStore.tourStepIndex + 1}/{totalSteps}
                </Typography>
              </Box>
            </Box>
          ),
          placement: 'top',
          disableBeacon: true,
          styles: {
            overlay: {
              pointerEvents: 'none',
            },
          },
        }
        : {
          target: ".horizontal-nav-item-container-today",
          content: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                backgroundColor: '#FFEFB7',
                borderRadius: 2,
                color: 'inherit',
                zIndex: 0,
                padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
                gap: pxToRem(8),
                position: 'relative',
              }}
              data-qa-id="third-tour-step-mobile"
            >
              {/* Left column */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: 'inherit', textAlign: 'left' }}>
                  Good work! And you can use the ‘Today’ button to jump back.
                </Typography>
              </Box>

              {/* Right column: X + step */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <IconButton
                  onClick={() => layoutStore.closeTour()}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '1.2rem',
                    alignSelf: 'flex-end',
                    p: 0,
                    mb: 2,
                  }}
                >
                  ✕
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
                >
                  {layoutStore.tourStepIndex + 1}/{totalSteps}
                </Typography>
              </Box>
            </Box>
          ),
          placement: 'bottom',
          disableBeacon: true,
          styles: {
            overlay: {
              pointerEvents: 'none',
            },
          },
        }),
    },

    // 4) fourth step
    {
      ...(isDesktop
        ? {
          target: ".open-month-view-desktop-icon",
          content: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                backgroundColor: '#FFEFB7',
                borderRadius: 2,
                color: 'inherit',
                zIndex: 0,
                padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
                gap: pxToRem(8),
                position: 'relative',
              }}
              data-qa-id="fourth-tour-step-desktop"
            >
              {/* Left column: text + continue */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: 'inherit', textAlign: 'left' }}>
                  Awesome. To get a bird's-eye view of all prompts, use the calendar view.
                </Typography>

                <Stack direction="row" alignItems="end" justifyContent="space-between" sx={{ mt: 2 }}>
                  <IconButton
                    size="medium"
                    variant="outlined"
                    sx={{
                      borderRadius: pxToRem(8),
                      border: `${pxToRem(1)} solid`,
                      padding: `${pxToRem(4)} ${pxToRem(8)}`,
                      width: "max-content",
                      height: pxToRem(28),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      layoutStore.goToNextStep();
                    }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Continue
                    </Typography>
                  </IconButton>
                </Stack>
              </Box>

              {/* Right column: X + step */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <IconButton
                  onClick={() => layoutStore.closeTour()}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '1.2rem',
                    alignSelf: 'flex-end',
                    p: 0,
                    mb: 2,
                  }}
                >
                  ✕
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
                >
                  {layoutStore.tourStepIndex + 1}/{totalSteps}
                </Typography>
              </Box>
            </Box>
          ),
          placement: 'top',
          disableBeacon: true,
        }
        : {
          target: ".open-month-view-mobile-icon",
          content: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                backgroundColor: '#FFEFB7',
                borderRadius: 2,
                color: 'inherit',
                zIndex: 0,
                padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
                gap: pxToRem(8),
                position: 'relative',
              }}
              data-qa-id="fourth-tour-step-mobile"
            >
              {/* Left column */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: 'inherit', textAlign: 'left' }}>
                  Awesome. To get a bird's-eye view of all prompts, use the calendar view.
                </Typography>

                <Stack direction="row" alignItems="end" justifyContent="space-between" sx={{ mt: 2 }}>
                  <IconButton
                    size="medium"
                    variant="outlined"
                    sx={{
                      borderRadius: pxToRem(8),
                      border: `${pxToRem(1)} solid`,
                      padding: `${pxToRem(4)} ${pxToRem(8)}`,
                      width: "max-content",
                      height: pxToRem(28),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => layoutStore.goToNextStep()}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Continue
                    </Typography>
                  </IconButton>
                </Stack>
              </Box>

              {/* Right column: X + step */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <IconButton
                  onClick={() => layoutStore.closeTour()}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '1.2rem',
                    alignSelf: 'flex-end',
                    p: 0,
                    mb: 2,
                  }}
                >
                  ✕
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
                >
                  {layoutStore.tourStepIndex + 1}/{totalSteps}
                </Typography>
              </Box>
            </Box>
          ),
          placement: 'bottom',
          disableBeacon: true,
        }),
    },

    // 5) fifth step
    {
      ...(isDesktop
        ? {
          target: ".toolbox-view-desktop-icon",
          content: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                backgroundColor: '#FFEFB7',
                borderRadius: 2,
                color: 'inherit',
                zIndex: 0,
                padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
                gap: pxToRem(8),
                position: 'relative',
              }}
              data-qa-id="fifth-tour-step-desktop"
            >
              {/* Left column: text + continue */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: 'inherit', textAlign: 'left' }}>
                  OCD journaling can be tough. Use this toolbox to help resist your OCD compulsions.
                </Typography>

                <Stack direction="row" alignItems="end" justifyContent="space-between" sx={{ mt: 2 }}>
                  <IconButton
                    size="medium"
                    variant="outlined"
                    sx={{
                      borderRadius: pxToRem(8),
                      border: `${pxToRem(1)} solid`,
                      padding: `${pxToRem(4)} ${pxToRem(8)}`,
                      width: "max-content",
                      height: pxToRem(28),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => layoutStore.goToNextStep()}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Continue
                    </Typography>
                  </IconButton>
                </Stack>
              </Box>

              {/* Right column: X + step */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <IconButton
                  onClick={() => layoutStore.closeTour()}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '1.2rem',
                    alignSelf: 'flex-end',
                    p: 0,
                    mb: 2,
                  }}
                >
                  ✕
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
                >
                  {layoutStore.tourStepIndex + 1}/{totalSteps}
                </Typography>
              </Box>
            </Box>
          ),
          placement: 'bottom',
          disableBeacon: true,
        }
        : {
          target: ".toolbox-view-mobile-icon",
          content: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                backgroundColor: '#FFEFB7',
                borderRadius: 2,
                color: 'inherit',
                zIndex: 0,
                padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
                gap: pxToRem(8),
                position: 'relative',
              }}
              data-qa-id="fifth-tour-step-mobile"
            >
              {/* Left column */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: 'inherit', textAlign: 'left' }}>
                  OCD journaling can be tough. Use this toolbox to help resist your OCD compulsions.
                </Typography>

                <Stack direction="row" alignItems="end" justifyContent="space-between" sx={{ mt: 2 }}>
                  <IconButton
                    size="medium"
                    variant="outlined"
                    sx={{
                      borderRadius: pxToRem(8),
                      border: `${pxToRem(1)} solid`,
                      padding: `${pxToRem(4)} ${pxToRem(8)}`,
                      width: "max-content",
                      height: pxToRem(28),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => layoutStore.goToNextStep()}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Continue
                    </Typography>
                  </IconButton>
                </Stack>
              </Box>

              {/* Right column: X + step */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <IconButton
                  onClick={() => layoutStore.closeTour()}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '1.2rem',
                    alignSelf: 'flex-end',
                    p: 0,
                    mb: 2,
                  }}
                >
                  ✕
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
                >
                  {layoutStore.tourStepIndex + 1}/{totalSteps}
                </Typography>
              </Box>
            </Box>
          ),
          placement: 'bottom',
          disableBeacon: true,
        }),
    },

    // 6) sixth step
    {
      ...(isDesktop
        ? {
          target: ".streak-button",
          content: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                backgroundColor: '#FFEFB7',
                borderRadius: 2,
                color: 'inherit',
                zIndex: 0,
                padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
                gap: pxToRem(8),
                position: 'relative',
              }}
              data-qa-id="sixth-tour-step-desktop"
            >
              {/* Left column */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: 'inherit', textAlign: 'left' }}>
                  And this is where you can see your streak—the number of days you’ve journaled every day in a row
                </Typography>

                <Stack direction="row" alignItems="end" justifyContent="space-between" sx={{ mt: 2 }}>
                  <IconButton
                    size="medium"
                    variant="outlined"
                    sx={{
                      borderRadius: pxToRem(8),
                      border: `${pxToRem(1)} solid`,
                      padding: `${pxToRem(4)} ${pxToRem(8)}`,
                      width: "max-content",
                      height: pxToRem(28),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => layoutStore.goToNextStep()}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Get to journaling
                    </Typography>
                  </IconButton>
                </Stack>
              </Box>

              {/* Right column: X + step */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <IconButton
                  onClick={() => layoutStore.closeTour()}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '1.2rem',
                    alignSelf: 'flex-end',
                    p: 0,
                    mb: 2,
                  }}
                >
                  ✕
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
                >
                  {layoutStore.tourStepIndex + 1}/{totalSteps}
                </Typography>
              </Box>
            </Box>
          ),
          placement: 'top',
          disableBeacon: true,
        }
        : {
          target: ".streak-button",
          content: (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr auto',
                backgroundColor: '#FFEFB7',
                borderRadius: 2,
                color: 'inherit',
                zIndex: 0,
                padding: `${pxToRem(20)} ${pxToRem(20)} 0 ${pxToRem(20)}`,
                gap: pxToRem(8),
                position: 'relative',
              }}
              data-qa-id="sixth-tour-step-mobile"
            >
              {/* Left column */}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="body1" sx={{ color: 'inherit', textAlign: 'left' }}>
                  And this is where you can see your streak—the number of days you’ve journaled every day in a row
                </Typography>

                <Stack direction="row" alignItems="end" justifyContent="space-between" sx={{ mt: 2 }}>
                  <IconButton
                    size="medium"
                    variant="outlined"
                    sx={{
                      borderRadius: pxToRem(8),
                      border: `${pxToRem(1)} solid`,
                      padding: `${pxToRem(4)} ${pxToRem(8)}`,
                      width: "max-content",
                      height: pxToRem(28),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => layoutStore.goToNextStep()}
                  >
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Get to journaling
                    </Typography>
                  </IconButton>
                </Stack>
              </Box>

              {/* Right column: X + step */}
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <IconButton
                  onClick={() => layoutStore.closeTour()}
                  sx={{
                    backgroundColor: 'transparent',
                    fontSize: '1.2rem',
                    alignSelf: 'flex-end',
                    p: 0,
                    mb: 2,
                  }}
                >
                  ✕
                </IconButton>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 600, color: "#8E7C56", mt: 'auto' }}
                >
                  {layoutStore.tourStepIndex + 1}/{totalSteps}
                </Typography>
              </Box>
            </Box>
          ),
          placement: 'bottom',
          disableBeacon: true,
        }),
    },
  ];
};

export default getOnboardingTourSteps;
