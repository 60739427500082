import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useAuthContext } from "../../auth/useAuthContext";
import { Typography, Box, styled, IconButton } from "@mui/material";
import { pxToRem } from "../../theme/typography";
import SvgColor from "../svg-color";
import useResponsive from "../../hooks/useResponsive";
import { fadeIn } from "./styles";
import getStreakSummaryContent from "../../utils/streakSummaryContent";
import BaseModal from "../base-modal/BaseModal";
import { useStoresContext } from "../../stores/useStoresContext";
import { GUMDROP_FIRE_WAVING } from "../../constants/character-animation-events";
import CharacterAnimation from "../../animation/CharacterAnimation";
import { useTheme } from "@mui/material/styles";
import { layoutStore } from "../../stores/layout-store";

const AnimatedTypography = styled(Typography)(({ theme, condensed }) => ({
    fontWeight: "bold",
    animation: `${condensed ? fadeIn : "none"} 0.5s ease-in-out`,
    color: theme.palette.secondary.contrastText,
    fontSize: pxToRem(14),
}));

const BottomSection = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: theme.palette.background.neutral,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    width: "100%",
}));

const StatBox = styled(Box)(({ theme }) => ({
    textAlign: "center",
    "& .MuiTypography-h4": {
        color: theme.palette.text.primary, // Use the same text color as the rest of the dialog
    },
    "& .MuiTypography-body2": {
        color: theme.palette.text.secondary,
    },
}));

const VerticalDivider = styled(Box)(({ theme }) => ({
    width: pxToRem(2),
    height: pxToRem(30),
    backgroundColor: theme.palette.card.borderStroke,
}));

const StreakDialog = observer(({ open, handleClose, user }) => {
    const currentStreak = user?.userStreak?.current_streak || 0;
    const questionAnswerCount = user?.questionSubscriptionCount || 0;

    const isDesktop = useResponsive('up', 'lg');
    const isTallScreen = useResponsive('up', '700px', undefined, 'height'); // Check if height is greater than 700px

    const descriptionText = getStreakSummaryContent(currentStreak);

    const { isReady: storesAreReady, characterAnimationStore } = useStoresContext();

    useEffect(() => {
        const initialize = async () => {
            if (storesAreReady) {
                characterAnimationStore.updateAnimation(GUMDROP_FIRE_WAVING)
            }
        };

        initialize();

        // reset on dialog unmount
        return () => {
            characterAnimationStore.reset();
        }

    }, [characterAnimationStore, storesAreReady]);

    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            title="Summary"
            subheaderText="STREAK"
            description={descriptionText}
            fullScreen={!isDesktop}
        >
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: `${pxToRem(32)} !important` }} data-qa-id={"gumdrop-streak-container"}>
                <CharacterAnimation
                    animationData={characterAnimationStore.currentAnimationData}
                    style={{
                        height: isDesktop ? pxToRem(180) : (isTallScreen ? pxToRem(180) : pxToRem(140)),
                        width: isDesktop ? pxToRem(180) : (isTallScreen ? pxToRem(180) : pxToRem(160)),
                    }}
                />
            </Box>
            <BottomSection data-qa-id={"bottom-section"}>
                <StatBox>
                    <Typography variant="h4">{currentStreak}</Typography>
                    <Typography variant="body2">Current Streak</Typography>
                </StatBox>
                <VerticalDivider data-qa-id={"vertical-divider"} />
                <StatBox>
                    <Typography variant="h4">{questionAnswerCount}</Typography>
                    <Typography variant="body2">Total Entries</Typography>
                </StatBox>
            </BottomSection>
            <Typography variant="caption" sx={{
                marginTop: `${pxToRem(16)} !important`, fontWeight: 400,
                fontSize: pxToRem(14),
            }} data-qa-id="streak-instructional-info">
                Your streak is built on consecutive days of answering the daily question. Only "Today" answers count toward your streak. Past answers add to your total entries but not your streak.
            </Typography>
        </BaseModal>


    );
});

const Streak = observer(() => {
    const { user } = useAuthContext();

    const theme = useTheme();

    const currentStreak = user?.userStreak?.current_streak || 0;

    const isDesktop = useResponsive("up", "lg");

    const [condensed, setCondensed] = useState(!isDesktop);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        // showing condensed animation if tour is jarring
        if (isDesktop && !layoutStore.isTourOpen) {
            const timer = setTimeout(() => {
                setCondensed(true);
            }, 15000);

            return () => clearTimeout(timer);
        } else {
            setCondensed(true);
        }
    }, [isDesktop]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                size="small"
                variant="outlined"
                onClick={handleClickOpen}
                // className needed for onboarding tour
                className={"streak-button"}
                data-qa-id={"streak-button"}
                sx={{
                    borderRadius: pxToRem(8),
                    border: `${pxToRem(1)} solid`,
                    padding: `${isDesktop ? pxToRem(8) : pxToRem(4)} ${isDesktop ? pxToRem(22) : pxToRem(8)}`,
                    width: "max-content",
                    height: isDesktop ? pxToRem(28) : pxToRem(28),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: theme.palette.background.lightNeutral,
                }}
            >
                <SvgColor src={`/assets/icons/streak/fire.svg`} sx={{ width: pxToRem(12), height: pxToRem(16), color: theme.palette.secondary.contrastText, marginRight: pxToRem(8) }} data-qa-id={"streak-icon"} />
                <AnimatedTypography variant={isDesktop ? "body2" : "caption"} condensed={condensed}>
                    {condensed ? currentStreak : `Day ${currentStreak}`}
                </AnimatedTypography>
            </IconButton>

            <StreakDialog open={open} handleClose={handleClose} user={user} isDesktop={isDesktop} />
        </>
    );
});

export default Streak;
