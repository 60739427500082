import PropTypes from 'prop-types';
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useRef } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, FormHelperText } from '@mui/material';
import { pxToRem } from '../../theme/typography';

// ----------------------------------------------------------------------

RHFIndividualCharactersField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  isDesktop: PropTypes.bool.isRequired,
  numberKeyboardActive: PropTypes.bool, // Add this prop
  autoFocus: PropTypes.bool, // Add this prop
};

export default function RHFIndividualCharactersField({ name, helperText, isDesktop, numberKeyboardActive, autoFocus = false, ...other }) {
  const { control } = useFormContext();
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (value) => value?.length === 6 }}
      render={({ field, fieldState: { error } }) => (
        <Box>
          <MuiOtpInput
            ref={inputRef}
            sx={{
              gap: 1,
              '& .MuiInputBase-input': {
                padding: isDesktop ? `${pxToRem(16.5)} ${pxToRem(14)}` : `${pxToRem(16.5)} ${pxToRem(0)}`
              },
            }}
            autoFocus={autoFocus}
            {...field}
            length={6}
            {...other}
            TextFieldsProps={{
              inputProps: {
                autoComplete: "one-time-code",  // tell the browser this is an OTP field
                inputMode: numberKeyboardActive ? 'numeric' : 'text', // Set input mode to numeric if numberKeyboardActive is true
                pattern: numberKeyboardActive ? '[0-9]*' : undefined, // Set pattern for numeric input
              }
            }}
          />
          <FormHelperText error>{error ? error?.message : helperText}</FormHelperText>
        </Box>
      )}
    />
  );
}
