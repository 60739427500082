// i18n
import './locales/i18n';

import '@fontsource/vt323';
import '@fontsource/itim';

// lightbox
/* eslint-disable import/no-unresolved */
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

import React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Router from './routes';
// @mui
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { ThemeSettings, SettingsProvider } from './components/settings';
import SnackbarProvider from './components/snackbar';
import OnboardingTour from './components/onboarding-tour';
// stores
import { StoresProvider } from './stores/StoresContext';
import stores from './stores';

import "./index.css";

const App = observer(() => {

  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SettingsProvider>
          <BrowserRouter>
            <ThemeProvider>
              <ThemeSettings>
                <ThemeLocalization>
                  <SnackbarProvider>
                    <StoresProvider stores={stores}>
                      <OnboardingTour />
                      <Router />
                    </StoresProvider>
                  </SnackbarProvider>
                </ThemeLocalization>
              </ThemeSettings>
            </ThemeProvider>
          </BrowserRouter>
        </SettingsProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
});

export default App;
