import PropTypes from 'prop-types';
// @mui
import { Box } from '@mui/material';
// config
import { HEADER, NAV } from '../../config-global';
import { pxToRem } from '../../theme/typography';
import Logo from '../../components/logo';

// ----------------------------------------------------------------------

const SPACING = 8;

Main.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

export default function Main({ children, sx, isNavHorizontal = false, hideBottomPadding = false, hideTopPadding = false, hideHorizontalPadding = false, ...other }) {
  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          pt: hideTopPadding ? " inherit" : `${HEADER.H_MOBILE + SPACING * 3}px`,
          pb: hideBottomPadding ? " inherit" : `${HEADER.H_MOBILE + SPACING}px`,
          height: "100%",
          ...sx,
        }}
        data-qa-id={"main-container"}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        px: !hideHorizontalPadding && 2,
        pt: hideTopPadding ? pxToRem(48) : `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
        width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
        ...sx,
      }}
      {...other}
      data-qa-id={"main-container"}
    >
      {children}
    </Box >
  );
}
