import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
// config
import { API_URL } from '../config-global';
import cookies from './cookies';
import { PATH_AUTH } from '../routes/paths';
import { handleError } from './handleError';

// ----------------------------------------------------------------------
const filename = "/utils/axios.js";

const axiosInstance = axios.create({ baseURL: API_URL });

// ensure that we can set cookies 
axiosInstance.defaults.withCredentials = true;

const getDomain = () => {
  if (process.env.REACT_APP_VERCEL_ENV === "production") {
    return process.env.FRONTEND_DOMAIN_PRODUCTION;
  } else if (process.env.REACT_APP_VERCEL_ENV === "preview") {
    return process.env.FRONTEND_DOMAIN_PREVIEW;
  }
  return "localhost";
};

const handleForceLogout = async () => {
  const hasAccessOrRefreshToken = cookies.get('authSession') || cookies.get('refreshTokenID');

  if (hasAccessOrRefreshToken) {
    await axiosInstance.get('/auth/logout');

    // For non-httpOnly cookies
    cookies.remove('authSession', { path: '/', domain: getDomain() });
    cookies.remove('refreshTokenID', { path: '/', domain: getDomain() });
  }

  window.location.href = PATH_AUTH.login;

  setTimeout(() => {
    window.location.reload();
  }, 100);
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status) {

      if (error.response.status === StatusCodes.UNAUTHORIZED) {
        // refreshTokenID is true when refreshToken exists
        const hasRefreshToken = cookies.get('refreshTokenID');

        if (!error.config?.noRefresh && hasRefreshToken) {
          return axiosInstance.post('/auth/refresh', {
            withCredentials: true,
            noRefresh: true,
          }).then(() => {
            // tries the request again after refreshing
            return axiosInstance(error.config, { noRefresh: true });
          })
        } else {
          await handleForceLogout();
        }
      } else if (error.response.status === StatusCodes.FORBIDDEN) {
        handleError({
          error: error,
          message: `Error with request - ${StatusCodes.FORBIDDEN}`,
          filename,
          functionName: "axiosInstance",
        });

        await handleForceLogout();
      } else {
        return error.response;
      }
    }
  }
);

export default axiosInstance;
