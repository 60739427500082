// components/snackbar/SuccessfulAnswerToast.js
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import { SnackbarContent } from 'notistack';
import Iconify from '../../iconify';
import { pxToRem } from '../../../theme/typography';
import useResponsive from '../../../hooks/useResponsive';
import { FEEDBACK, NAV } from '../../../config-global';

const SuccessfulAnswerToast = forwardRef(({ title, description, onClose }, ref) => {
    const isDesktop = useResponsive('up', 'lg');

    const theme = useTheme();

    return (
        <SnackbarContent ref={ref} >
            <StyledCard sx={{ marginBottom: isDesktop ? pxToRem(FEEDBACK.H_DESKTOP + 4) : pxToRem(NAV.H_BOTTOM_NAVIGATION_BAR_MOBILE) }} data-qa-id={"answer-toast-card"}>
                <StyledCardContent data-qa-id={"answer-toast-card-content"}>
                    <Box display="flex" alignItems="flex-start" data-qa-id={"answer-toast-main-box"}>
                        <Box ml={2}>
                            <Box display="flex" alignItems="center" data-qa-id={"answer-toast-title-icon-container"}>
                                <Iconify icon="fluent:checkmark-12-filled" width={24} height={24} sx={{ marginRight: pxToRem(12) }} color="success.main" />
                                <Typography variant="h6" sx={{
                                    margin: "0 !important",
                                    fontSize: pxToRem(16),
                                    fontFamily: theme.typography.fontFamilyLarge,
                                    fontWeight: theme.typography.fontWeightBold,
                                    textTransform: "none",
                                }}>
                                    {title}
                                </Typography>
                                <IconButton size="small" onClick={onClose} sx={{ p: 0.5, ml: isDesktop ? pxToRem(200) : "auto" }}>
                                    <Iconify icon="mingcute:close-fill" />
                                </IconButton>
                            </Box>
                            <Typography variant="caption" sx={{
                                marginLeft: pxToRem(36),
                                marginTop: pxToRem(8),
                                maxWidth: pxToRem(360),
                                fontSize: pxToRem(14),
                            }}>
                                {description}
                            </Typography>
                        </Box>
                    </Box>
                </StyledCardContent>
            </StyledCard>
        </SnackbarContent >
    );
});

SuccessfulAnswerToast.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onClose: PropTypes.func,
};

export default SuccessfulAnswerToast;

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: pxToRem(800),
    margin: 'auto',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[0],
    width: '100%',
    background: '#FFFBD8',
    border: `${pxToRem(2)} solid ${theme.palette.card.borderStroke}`,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiTypography-h6': {
        marginBottom: theme.spacing(1),
    },
    padding: "0 !important",
}));
