import React from 'react';
import { observer } from 'mobx-react';
import { Box, Typography, Button, AppBar } from '@mui/material';
import { useStoresContext } from '../../../stores/useStoresContext';
import PropTypes from 'prop-types';
import useResponsive from '../../../hooks/useResponsive';
import { COMPLETED_COPING_STRATEGY_PREMATURELY } from '../../../constants/analytics-events';
import { handleEvent } from '../../../utils/handleEvent';
import { pxToRem } from '../../../theme/typography';
import { HEADER } from '../../../config-global';
import { styled, useTheme } from '@mui/material/styles';

const PreCopingStatementStep = observer(({ onEnd }) => {
    const { copingStatementStore } = useStoresContext();

    const isDesktop = useResponsive('up', 'lg');

    const theme = useTheme();

    const handleContinue = () => {
        copingStatementStore.goToNextStep();
    };

    const handleEnd = () => {
        handleEvent({
            eventName: COMPLETED_COPING_STRATEGY_PREMATURELY,
            isDesktop,
            properties: {
                ending_screen: "precoping_statement_step"
            }
        });

        onEnd();
    }

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 2,
            }}
            data-qa-id="pre-coping-statement-step"
        >

            {/* Top bar with End button */}
            <StyledAppBar data-qa-id="header">
                <Button
                    onClick={handleEnd}
                    sx={{
                        fontWeight: 'bold',
                        textTransform: 'none',
                        color: "#2B3A6C"
                    }}
                    data-qa-id="end-button"
                >
                    Back to my journal
                </Button>
            </StyledAppBar>

            {/* Content */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: isDesktop ? 'center' : 'flex-start',
                }}
                data-qa-id="content-container"
            >
                <Typography
                    variant={isDesktop ? "h3" : "h4"}
                    align="left"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                        color: "inherit",
                        fontFamily: theme.typography.fontFamilyLarge,
                    }}
                    data-qa-id="instruction-text-first"
                >
                    That's okay. You might feel the urge to ritualize.
                </Typography>

                {/* Add more space between the sentence completion and examples */}
                <Box sx={{ height: isDesktop ? 4 : 16 }} /> {/* Adds 16px of space */}

                <Typography
                    variant={isDesktop ? "h3" : "h4"}
                    align={isDesktop ? "center" : "left"}
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: 2,
                        color: "inherit",
                        fontFamily: theme.typography.fontFamilyLarge,
                    }}
                    data-qa-id="instruction-text-second"
                >
                    Let's resist with a strong coping statement.
                </Typography>


            </Box>
            {isDesktop && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        width: "50%",
                        margin: `${pxToRem(160)} auto 0 auto`,
                    }}
                    data-qa-id="continue-button-container"
                >
                    <Button
                        variant="contained"
                        fullWidth={"true"}
                        onClick={handleContinue}
                        sx={{
                            backgroundColor: '#2B3A6C !important',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#2B3A6C !important',
                                '&::before': {
                                    backgroundColor: "#170161 !important",

                                },
                            },
                            '&::before': {
                                backgroundColor: "#170161 !important",

                            },
                        }}
                        data-qa-id="continue-button"
                    >
                        I'm ready
                    </Button>
                </Box>
            )}

            {
                !isDesktop && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 2,
                        }}
                        data-qa-id="continue-button-container"
                    >
                        <Button
                            variant="contained"
                            fullWidth={true}
                            onClick={handleContinue}
                            sx={{
                                backgroundColor: '#2B3A6C !important',
                                fontWeight: 'bold',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#2B3A6C !important',
                                    '&::before': {
                                        backgroundColor: "#170161 !important",

                                    },
                                },
                                '&::before': {
                                    backgroundColor: "#170161 !important",

                                },
                            }}
                            data-qa-id="continue-button"
                        >
                            I'm ready
                        </Button>
                    </Box>
                )
            }
        </Box >
    );
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    height: HEADER.H_MOBILE,
    zIndex: theme.zIndex.appBar + 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    backgroundColor: "#77ADFF",
    backgroundSize: "20px 20px",
    position: 'fixed',
    paddingRight: pxToRem(16),
    color: "inherit",
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: pxToRem(2),
        backgroundColor: "#5C96EC",
    },
}));

PreCopingStatementStep.propTypes = {
    onEnd: PropTypes.func.isRequired,
};

export default PreCopingStatementStep;
